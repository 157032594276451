<template>
  <b-container fluid="xl">
    <div style="max-width: 100%; margin: 0 auto; margin-top: 40px">
      <b-img
        fluid
        src="@/assets/maintenance-mode/header.png"
        alt="Let's Talk Science"
        class="headerimg"
      />
    </div>

    <h1 class="h2">{{ $t("MaintenanceMode.Header") }}</h1>

    <div>
      <p>
        {{ $t("MaintenanceMode.Intro") }}
      </p>
      <hr />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component
export default class MaintenanceMode extends Vue {
  model: any = {};
}
</script>
