import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class FeaturesApiService extends BaseApiService {
  async GetFeatures(): Promise<UseCaseResponse<string[]>> {
    return axios
      .get(`${this.url}/api/public/features/Feature`)
      .then((resp) => resp.data as UseCaseResponse<string[]>);
  }
}
