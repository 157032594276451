<template>
  <div id="app" v-if="loaded">
    <header class="header" role="banner">
      <b-navbar
        type="dark"
        variant="header-blue"
        toggleable="false"
        id="navbar-top"
      >
        <div class="d-flex">
          <a
            v-if="$i18n.locale === 'fr'"
            href="https://parlonssciences.ca/tous-nos-programmes"
            class="text-white"
            >Tous nos programmes</a
          >
          <a
            v-else
            href="https://letstalkscience.ca/all-programming-info"
            class="text-white"
            >All Programming</a
          >
        </div>
        <div class="region-top-header-form d-flex ml-0 mr-1">
          <div class="bluebar-lang">
            <nav class="links nav links-inline">
              <span hreflang="en" class="en nav-link">
                <b-btn @click="lang('en')" :class="getLangClasses('en')"
                  >EN</b-btn
                >
              </span>
              <span hreflang="fr" class="fr nav-link">
                <b-btn @click="lang('fr')" :class="getLangClasses('fr')"
                  >FR</b-btn
                >
              </span>
              <span v-if="Localizer" hreflang="debug" class="debug nav-link">
                <b-btn @click="lang('debug')" :class="getLangClasses('debug')"
                  >DEBUG</b-btn
                >
              </span>
            </nav>
          </div>
          <div>
            <a
              v-if="$i18n.locale === 'fr'"
              href="https://parlonssciences.ca/nos-supporters/faire-un-don"
              class="btn btn-block bg-white border border-primary rounded rounded-0 text-primary text-center py-2 mw-donate"
              target="_blank"
              >Faire un don<span class="sr-only"
                >S'ouvre dans une nouvelle fenêtre</span
              >
              <i class="fa-solid fa-circle-dollar-to-slot"></i>
            </a>
            <a
              v-else
              href="https://letstalkscience.ca/our-supporters/donate"
              class="btn btn-block bg-white border border-primary rounded rounded-0 text-primary text-center py-2 mw-donate"
              target="_blank"
              >Donate<span class="sr-only">Opens in new window</span>
              <i class="fa-solid fa-circle-dollar-to-slot"></i>
            </a>

            <div></div>
          </div>
        </div>
      </b-navbar>
      <b-navbar type="light" toggleable="true" id="navbar-main">
        <div class="d-flex">
          <a
            v-if="$i18n.locale === 'fr'"
            href="https://parlonssciences.ca"
            target="_blank"
          >
            <b-img
              src="@/assets/FR_LTS_30.svg"
              class="lts-logo-styles"
              alt="Parlons sciences"
            />
          </a>
          <a v-else href="https://letstalkscience.ca" target="_blank">
            <b-img
              src="@/assets/EN_LTS_30.svg"
              class="lts-logo-styles"
              alt="Let's Talk Science"
            />
          </a>
          <div v-if="Elevated">
            <b-dropdown
              id="forms-dropdown"
              text="Admin Navigation"
              variant="primary"
            >
              <b-dropdown-item v-for="(r, idx) in routes" :key="idx">
                <router-link :to="r.path">{{ r.name }}</router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-navbar>
    </header>
    <main role="main" class="flex-shrink-0">
      <div class="pb-4">
        <router-view />
      </div>
    </main>
    <footer
      v-if="$i18n.locale === 'fr'"
      class="site-footer mt-auto bg-dark py-3"
    >
      <div class="site-footer-1 d-block d-lg-flex px-4">
        <div class="footer-left d-block d-md-flex col-md-6 col-lg-8">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <h4>Suivez-nous</h4>

            <ul class="list-unstyled list-dense">
              <li>
                <a
                  class="twitter text-decoration-none"
                  href="https://twitter.com/parlonssciences"
                  ><img
                    alt=""
                    src="/sites/default/files/x-twitter.svg"
                    style="height: 15px"
                  />
                  <u>@ParlonsSciences</u><span class="sr-only"> - Twitter</span>
                </a>
              </li>
              <li>
                <a
                  class="facebook text-decoration-none"
                  href="https://www.facebook.com/Parlonssciencesfr/"
                  target="_blank"
                  ><i class="fab fa-facebook-square"></i>
                  <u>@ParlonsSciencesFR</u>
                  <span class="sr-only"> - Facebook</span>
                </a>
              </li>
              <li>
                <a
                  class="youtube text-decoration-none"
                  href="http://youtube.com/LetsTalkScience"
                  target="_blank"
                  ><i class="fab fa-youtube"></i> <u>LetsTalkScience</u>
                  <span class="sr-only"> - Youtube</span>
                </a>
              </li>
              <li>
                <a
                  class="instagram text-decoration-none"
                  href="https://www.instagram.com/parlonssciences/"
                  ><i class="fab fa-instagram"></i> <u>@parlonssciences</u
                  ><span class="sr-only"> - Instagram</span>
                </a>
              </li>
              <li>
                <a
                  class="linkedin text-decoration-none"
                  href="https://www.linkedin.com/company/let's-talk-science"
                  target="_blank"
                  ><i class="fab fa-linkedin"></i> <u>Let's Talk Science</u
                  ><span class="sr-only"> - LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <h4>Nous contacter</h4>

            <ul class="list-unstyled list-dense">
              <li>877.474.4081</li>
              <li>
                <a href="https://parlonssciences.ca/nous-contacter"
                  >Nous contacter</a
                >
              </li>
            </ul>
            <strong
              >Numéro d'enregistrement d'organisme de bienfaisance :</strong
            >
            88540 0846 RR0001
          </div>
        </div>
        <div class="footer-right col-12 col-md-6 col-lg-4 d-block d-md-flex">
          <div class="mailinglist">
            <h4 class="mb-2 d-block">L’Infolettre STIMulante</h4>

            <p class="pl-0 ml-0 mb-4" style="font-size: 14px">
              Deux fois par mois, nous faisons le lien entre les matières que
              vous enseignez et les enjeux les plus importants de l’heure au
              moyen d’activités, de ressources et de programmes liés aux STIM.
            </p>

            <p class="pl-0 ml-0">
              <a
                href="https://forms.zohopublic.com/nstcyr/form/SubscribetotheLetsTalkScienceEducatorNewsletter/formperma/kfrVxVfTgjQ5LB-S4Lp-fahtH6hfgacibt0gEi6ZfxE?zf_lang=fr"
                title="Abonnez-vous maintenant!"
                target="_blank"
                class="btn btn-light"
                >Abonnez-vous maintenant!</a
              >
            </p>
          </div>
        </div>
      </div>

      <div class="site-footer__bottom border-top-1 pt-2 pl-1">
        <p>
          Droit d’auteur 2024, Parlons sciences. Tous droits réservés.
          <a
            href="https://parlonssciences.ca/termes-et-politiques/politique-de-confidentialite"
            >Politique de confidentialité
          </a>
          &nbsp;
          <a
            href="https://parlonssciences.ca/termes-et-politiques/conditions-dutilisation"
            >Conditions d'utilisation </a
          >&nbsp;
          <a
            href="https://parlonssciences.ca/termes-et-politiques/accessibilite-du-service-a-la-clientele"
            >Accessibilité
          </a>
          &nbsp;
          <a
            href="https://parlonssciences.ca/termes-et-politiques/plaintes-dintegrite-scientifique"
            >Plaintes d'intégrité scientifique </a
          >&nbsp;
        </p>
      </div>
    </footer>
    <footer v-else class="site-footer mt-auto bg-dark">
      <div class="site-footer-1 d-block d-md-flex">
        <div class="footer-left d-block d-md-flex col-md-8 flex-wrap">
          <div class="col-12 col-sm-6 col-md-6">
            <h4>Stay Connected</h4>

            <ul class="list-unstyled list-dense">
              <li>
                <a
                  class="twitter text-decoration-none"
                  href="https://twitter.com/LetsTalkScience"
                  target="_blank"
                  ><img
                    alt=""
                    src="/sites/default/files/x-twitter.svg"
                    style="height: 15px"
                  /><u>@LetsTalkScience</u
                  ><span class="sr-only"> - Twitter</span>
                </a>
              </li>
              <li>
                <a
                  class="facebook text-decoration-none"
                  href="https://www.facebook.com/LetsTalkScience/"
                  target="_blank"
                  ><i class="fab fa-facebook-square"></i>
                  <u>@LetsTalkScience</u>
                  <span class="sr-only"> - Go to Facebook</span>
                </a>
              </li>
              <li>
                <a
                  class="youtube text-decoration-none"
                  href="http://youtube.com/LetsTalkScience"
                  target="_blank"
                  ><i class="fab fa-youtube"></i> <u>LetsTalkScience</u>
                  <span class="sr-only"> - Youtube</span>
                </a>
              </li>
              <li>
                <a
                  class="instagram text-decoration-none"
                  href="https://www.instagram.com/letstalkscience_hq/"
                  ><i class="fab fa-instagram"></i> <u>@letstalkscience_hq</u
                  ><span class="sr-only"> - Instagram</span>
                </a>
              </li>
              <li>
                <a
                  class="linkedin text-decoration-none"
                  href="https://www.linkedin.com/company/let's-talk-science"
                  target="_blank"
                  ><i class="fab fa-linkedin"></i> <u>Let's Talk Science</u
                  ><span class="sr-only"> - LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-sm-6 col-md-6">
            <h4>Contact Us</h4>

            <ul class="list-unstyled list-dense">
              <li>877.474.4081</li>
              <li>
                <a href="https://letstalkscience.ca/contact-us">Contact Us</a>
              </li>
            </ul>
            <strong>Charitable registration number:</strong> 88540 0846 RR0001
          </div>
        </div>
        <div class="footer-right d-block d-md-flex col-md-4">
          <div class="mailinglist">
            <div class="d-block" style="max-width: 400px">
              <h4 class="mb-2 d-block">Classroom Connection</h4>

              <p class="pl-0 ml-0 mb-4" style="font-size: 14px">
                Delivered twice each month,&nbsp;we're connecting the most
                important educational and global topics of our time across all
                classrooms through STEM-based resources, programs, and
                activities.
              </p>

              <p class="pl-0 ml-0">
                <a
                  class="btn btn-light p-1 m-0"
                  href="https://forms.zohopublic.com/nstcyr/form/SubscribetotheLetsTalkScienceEducatorNewsletter/formperma/kfrVxVfTgjQ5LB-S4Lp-fahtH6hfgacibt0gEi6ZfxE"
                  target="_blank"
                  >Subscribe Now</a
                >
              </p>
            </div>
          </div>
        </div>
        <!--end-footer-right-->
      </div>
      <!--end site-footer-1-->

      <div class="site-footer__bottom border border-top-1 pt-2 pl-1">
        <p>
          Copyright 2024, Let’s Talk Science, All Rights Reserved.
          <a href="https://letstalkscience.ca/terms-and-policies/privacy-policy"
            >Privacy Policy </a
          >&nbsp;
          <a href="https://letstalkscience.ca/terms-and-policies/terms-use"
            >Terms of Use </a
          >&nbsp;
          <a
            href="https://letstalkscience.ca/terms-and-policies/accessible-customer-service"
            >Accessibility </a
          >&nbsp;
          <a
            href="https://letstalkscience.ca/terms-and-policies/scientific-integrity-policy"
            >Scientific Integrity Policy </a
          >&nbsp;
        </p>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SecurityService from "./services/SecurityService";
import { extend, setInteractionMode } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import i18n from "./i18n";
import AuthorizationService from "./services/AuthorizationService";
import LocalizationApiService from "./services/LocalizationApiService";
import { RouteMeta, RouteRecordPublic } from "vue-router";
import Subject from "./types/Subject";
import AnalyticService from "@/services/AnalyticService";
import FeaturesApiService from "./services/FeaturesApiService";
import ConfigurationService from "./services/ConfigurationService";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Validation.Required",
});

@Component
export default class App extends Vue {
  securityService: SecurityService = SecurityService.getInstance();
  localizationApiService: LocalizationApiService = new LocalizationApiService();

  analyticService: AnalyticService = new AnalyticService(this.$router);

  featuresApiService: FeaturesApiService = new FeaturesApiService();
  features: string[] = [];

  routes: any[] = [];

  signed_in = false;
  localizations_loaded = false;

  configService: ConfigurationService = ConfigurationService.getInstance();

  loaded = false;
  get Loaded(): boolean {
    return this.loaded;
  }

  set Loaded(l: boolean) {
    this.loaded = l;
    this.analyticService.Enabled = this.loaded;
  }

  get AuthService(): AuthorizationService | undefined {
    return AuthorizationService.getInstance();
  }

  get Localizer(): boolean {
    return this.AuthService?.hasRole(["localizer", "administrator"]) || false;
  }

  get Elevated(): boolean {
    return (
      this.AuthService?.hasRole(["administrator", "staffadmin", "staff"]) ||
      false
    );
  }

  get menuItems(): any[] {
    return [
      {
        link: {
          en: "https://volunteer.app.letstalkscience.ca",
          fr: "https://volunteer.app.parlonssciences.ca",
        },
        text: {
          en: "Volunteer",
          fr: "Bénévole",
        },
        class: "lts-volunteer-icon",
      },
      {
        link: {
          en: "https://letstalkscience.instructure.com",
          fr: "https://letstalkscience.instructure.com",
        },
        text: {
          en: "Canvas LMS",
          fr: "Canvas LMS",
        },
        class: "lts-selfpaced-icon",
      },
      {
        link: {
          en: "http://letstalkscience.ca/lsev",
          fr: "http://parlonssciences.ca/lsev",
        },
        text: {
          en: "Living Space",
          fr: "Espace vivant",
        },
        class: "lts-livingspace-icon",
      },
      {
        link: {
          en: "https://tomatosphere.app.letstalkscience.ca/",
          fr: "https://tomatosphere.app.parlonssciences.ca/",
        },
        text: {
          en: "Tomatosphere",
          fr: "Tomatosphère",
        },
        class: "lts-tomatosphere-icon",
      },
    ];
  }

  get MaintenanceMode(): boolean {
    return this.features.includes("cr_maintenance_mode");
  }

  get ReturnUrl(): string {
    return this.$route.query.returnurl?.toString();
  }

  async created() {
    this.features = await (await this.featuresApiService.GetFeatures()).Value;
    if (this.MaintenanceMode && this.ReturnUrl) {
      this.ReturnUrlRedirect();
    }

    //configure router
    this.$router.afterEach(() => {
      this.checkRoute();
    });

    this.$router.beforeEach((to, from, next) => {
      if (this.MaintenanceMode && to.name !== "MaintenanceMode") {
        next("/MaintenanceMode");
      } else if (
        to.fullPath.toLowerCase().includes("admin") &&
        !this.Elevated
      ) {
        next("/Unauthorized");
      }

      next();
    });

    this.processLocaleFiles();
    this.buildFormMenu();

    if (
      this.MaintenanceMode &&
      this.$router.currentRoute.name !== "MaintenanceMode"
    ) {
      this.$router.push("/MaintenanceMode");
    }

    //check token for initial route
    this.checkRoute();
  }

  ReturnUrlRedirect() {
    if (this.ReturnUrl !== null) {
      const id_url: string = this.configService.getUrl("ltsol_id");
      const url = `${id_url}/Account/RedirectWithCode?returnurl=${encodeURIComponent(
        this.ReturnUrl
      )}`;
      window.location = url as any;
    }
  }

  async checkRoute() {
    const m: RouteMeta | undefined = this.$router.currentRoute.meta;
    if (m && m.no_auth === true) {
      this.Loaded = true;
    } else {
      await this.checkSignedIn();
    }

    if (this.$route.query.debug_lang === "true") {
      this.lang("debug");
    }
  }

  async checkSignedIn() {
    this.signed_in = await this.securityService.getSignedIn();
    if (this.signed_in) {
      const u: any = await this.securityService.getUser();
      const s: Subject = new Subject();
      s.SubjectId = u.profile.sub;
      s.GivenName = u.profile.given_name;
      s.FamilyName = u.profile.family_name;
      s.Roles = u.profile.role;
      AuthorizationService.init(s);

      if (
        this.$router.currentRoute.fullPath.toLowerCase().includes("admin") &&
        !this.Elevated
      ) {
        //reroute
        this.$router.push("/Unauthorized");
      }

      this.Loaded = true;
      console.log("loaded true");
    } else {
      this.Loaded = false;
    }
  }

  buildFormMenu() {
    //removes :instance? from form routes
    let i_idx = 0;
    this.routes = this.$router
      .getRoutes()
      .sort((a: RouteRecordPublic, b: RouteRecordPublic) => {
        const an = a.name || "";
        const bn = b.name || "";
        if (an > bn) return 1;
        else if (an < bn) return -1;
        else return 0;
      })
      .map((rrp: RouteRecordPublic) => {
        i_idx = rrp.path.indexOf(":instance?");
        return {
          name: rrp.name,
          path: i_idx > 0 ? rrp.path.substring(0, i_idx) : rrp.path,
        };
      });
  }

  lang(locale: string) {
    this.$i18n.locale = locale;
    document.title = this.$t("SiteTitle").toString();
  }

  async processLocaleFiles(): Promise<boolean> {
    if (this.localizations_loaded) {
      return true;
    }

    const locale_files = JSON.parse(JSON.stringify(i18n.messages));
    const resp = await this.localizationApiService.GetLocalizationsFormatted();

    if (resp.Success) {
      const localeObject: any = resp.Value;
      for (const locale in localeObject) {
        i18n.mergeLocaleMessage(locale, localeObject[locale]);
      }

      if (this.Localizer) {
        this.buildDebugLocale(locale_files);
      }

      this.localizations_loaded = true;
    }

    return resp.Success;
  }

  getLangClasses(locale: string): string {
    return this.$i18n.locale === locale
      ? "language-link is-active"
      : "language-link";
  }

  buildDebugLocale(locale_files: any) {
    const dbgLocale: any = {};

    for (const key in (locale_files as any)["en"]) {
      dbgLocale[key] = key;
    }

    i18n.mergeLocaleMessage("debug", dbgLocale);
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h5.required-field:after,
p.required-field:after,
.required-field label:after {
  content: " \2a";
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.form-group {
  margin-bottom: 0 !important;
}

.invalid-feedback {
  color: #cd2334 !important;
}

.ws-header {
  margin-top: 0;
}
footer {
  color: #fff !important;
}
.site-footer__bottom a,
.site-footer__bottom a:hover,
.site-footer__bottom a:hover:active {
  color: #fff !important;
  text-decoration: underline;
}

.footer-left a,
.footer-left a:hover,
.footer-left a:active {
  color: #fff;
  text-decoration: underline;
  display: block;
  white-space: nowrap;
  padding: 0.5rem 0;
}
label {
  font-weight: 600;
  font-size: 18px;
}
</style>
